import { makeStyles } from '@material-ui/core/styles';
import AppleIcon from '@material-ui/icons/Apple';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
import { ReactComponent as MicrosoftIcon } from 'images/microsoft.svg';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { TargetStatus } from '../../assets/targets/TargetStatus';
import { HostDetailsModal } from './HostDetailsModal';

const useStyles = makeStyles((theme) => ({
  root: {},

  infoBox: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    background: 'rgba(218, 216, 216, 0.1)',
    border: '1px solid #D0D0D0',
    borderRadius: theme.spacing(1),
  },

  statusBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'inline-flex',
    width: 'auto',
  },
  statusContent: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    gap: theme.spacing(0.5),
    fontWeight: 400,
    color: COLORS.BLACK,
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  macColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const generateKey = (row: DeepScanNetworkHostDTO) => `${row.macAddress}-${row.hostName}`;

interface Props {
  deepScanTargets: DeepScanTargetDTO[];
  deepScanNetworkHosts: DeepScanNetworkHostDTO[] | undefined;
}

export const InventoryDevices = ({ deepScanTargets, deepScanNetworkHosts }: Props) => {
  const classes = useStyles();

  const [sorter, setSorter] = useState<Sorter<keyof DeepScanNetworkHostDTO> | undefined>({
    key: 'ipAddress',
    order: SortOrder.ASC,
  });

  const columns = useMemo((): Column<DeepScanNetworkHostDTO, keyof DeepScanNetworkHostDTO>[] => {
    const columns: Column<DeepScanNetworkHostDTO, keyof DeepScanNetworkHostDTO>[] = [
      {
        title: 'IP Address',
        sortKey: 'ipAddress',
        render: (row) => <div className={classes.macColumn}>{row.ipAddress}</div>,
      },
      {
        title: 'Host Name',
        sortKey: 'hostName',
        render: (row) => <div className={classes.macColumn}>{row.hostName}</div>,
      },
      {
        title: 'Mac Address',
        sortKey: 'macAddress',
        render: (row) => <div className={classes.macColumn}>{row.macAddress}</div>,
      },
      {
        title: 'Device Vendor',
        // sortKey: 'macAddressData?.vendorName',
        render: (row) => <div className={classes.macColumn}>{row.macAddressData?.vendorName}</div>,
      },
      {
        title: 'Telivy Deployed',
        // sortKey: 'macAddressData?.vendorName',
        render: (row) => {
          const target = deepScanTargets.find((t) => t.macAddress === row.macAddress);

          return target && <TargetStatus status='install-success'>&nbsp;</TargetStatus>;
        },
      },
      {
        title: 'OS Version',
        sortKey: 'osVersion',
        render: (row) => {
          const target = deepScanTargets.find((t) => t.macAddress === row.macAddress);
          if (!target) return '';

          return (
            <div className={classes.macColumn}>
              {target.osVersion?.includes('macOS') && <AppleIcon />}
              {target.osVersion?.includes('Windows') && <MicrosoftIcon />}
              {target.osVersion}
            </div>
          );
        },
      },
      // {
      //   title: 'Probable OS and Version',
      //   sortKey: 'osVersion',
      //   render: (row) => (
      //     <div className={classes.macColumn}>
      //       {row.osVersion?.includes('macOS') && <AppleIcon />}
      //       {row.osVersion?.includes('Windows') && <MicrosoftIcon />}
      //       {row.osVersion}
      //     </div>
      //   ),
      // },
      {
        title: 'Results',
        render: (row) => {
          const target = deepScanTargets.find((t) => t.macAddress === row.macAddress);

          return (
            <div className={classes.row}>
              <HostDetailsModal deepScanTarget={target} networkHost={row} deepScanId={row.deepScanId} label='View' />
            </div>
          );
        },
      },
    ];

    return columns;
  }, [classes.macColumn, classes.row, deepScanTargets]);

  const sortedNetworkHosts = useMemo(() => {
    const uniqNetworkHosts = uniqBy(deepScanNetworkHosts, 'ipAddress');

    if (sorter && uniqNetworkHosts) {
      const data = sortBy(uniqNetworkHosts, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return uniqNetworkHosts;
  }, [deepScanNetworkHosts, sorter]);

  return (
    <Table<DeepScanNetworkHostDTO, keyof DeepScanNetworkHostDTO>
      columns={columns}
      rowContentCentered
      rowKey={generateKey}
      data={sortedNetworkHosts}
      sorter={sorter}
      onChange={(pagination, sorter) => {
        setSorter(sorter);
      }}
    />
  );
};
