import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ListIcon from '@material-ui/icons/List';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import SettingsOutlineIcon from '@material-ui/icons/SettingsOutlined';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createSecurityRoute, insertIdToRoute, ROUTES } from 'telivy-constants';
import { isCyberInsuranceLine, isScanApplication, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const {
  CONFIGURATION,
  FORM,
  targets,
  inventory,
  PROPOSAL,
  QUOTES,
  POLICIES,
  REPORTS,
  SERVICES,
  DOCUMENTS,
  MONITORING,
  security,
} = ROUTES.agent.application;

interface MenuLink {
  label: string;
  icon: React.ReactNode;
  url: string;
  hide?: boolean;
  exactUrl?: boolean;
  floatRight?: boolean;
}

const generateMenuItems = (app: ApplicationWithAgentDTO, agent: AgentDTO): MenuLink[] => {
  let items: MenuLink[] = [
    {
      label: 'Quotes',
      icon: <FormatQuoteRoundedIcon />,
      url: insertIdToRoute(QUOTES, app.id),
      hide: isSecurityApplication(app) || !isCyberInsuranceLine(app),
      exactUrl: true,
    },
    {
      label: 'Proposal',
      icon: <StarOutlineRoundedIcon />,
      url: insertIdToRoute(PROPOSAL, app.id),
      hide: isSecurityApplication(app) || !isCyberInsuranceLine(app),
      exactUrl: true,
    },
    {
      label: 'Policy',
      icon: <CheckCircleOutlineRoundedIcon />,
      url: insertIdToRoute(POLICIES, app.id),
      hide: isSecurityApplication(app),
      exactUrl: true,
    },
    {
      label: 'Documents',
      icon: <FileCopyOutlinedIcon />,
      url: insertIdToRoute(DOCUMENTS, app.id),
      hide: isSecurityApplication(app) || !isCyberInsuranceLine(app),
      exactUrl: true,
    },
  ];

  if (!isSecurityApplication(app) || agent.agency?.enableLeadMagnet) {
    items.unshift({
      label: isSecurityApplication(app) ? 'Questionnaire' : 'Application',
      icon: <InsertDriveFileOutlinedIcon />,
      url: insertIdToRoute(FORM, app.id),
      exactUrl: true,
    });

    // items.push({
    //   label: 'Reports',
    //   icon: <FileCopyOutlinedIcon />,
    //   url: createSecurityRoute(REPORTS, app.id),
    //   exactUrl: true,
    // });
  }

  if (isScanApplication(app)) {
    items = [
      {
        label: 'Configuration',
        icon: <SettingsOutlineIcon />,
        url: insertIdToRoute(CONFIGURATION, app.id),
        exactUrl: true,
      },
    ];

    items.push({
      label: 'Targets',
      icon: <TrackChangesIcon />,
      url: insertIdToRoute(targets.ROOT, app.id),
      exactUrl: false,
    });

    items.push({
      label: 'Inventory',
      icon: <ListIcon />,
      url: insertIdToRoute(inventory.ROOT, app.id),
      exactUrl: false,
    });

    items.push({
      label: 'Questionnaire',
      icon: <InsertDriveFileOutlinedIcon />,
      url: insertIdToRoute(FORM, app.id),
      exactUrl: true,
    });

    items.push({
      label: 'Security',
      icon: <SecurityOutlinedIcon />,
      url: createSecurityRoute(security.ROOT, app.id, 'latest'),
      exactUrl: false,
    });

    items.push({
      label: 'Reports',
      icon: <FileCopyOutlinedIcon />,
      url: createSecurityRoute(REPORTS, app.id),
      exactUrl: true,
    });

    items.push({
      label: 'Services',
      icon: <SettingsApplicationsOutlinedIcon />,
      url: createSecurityRoute(SERVICES, app.id),
      exactUrl: true,
    });

    if (app.monitoringEnabled) {
      items.push({
        label: 'Monitoring',
        icon: <HistoryRoundedIcon />,
        url: insertIdToRoute(MONITORING, app.id),
        exactUrl: true,
        floatRight: true,
      });
    }
  } else if (isSecurityApplication(app)) {
    // items.unshift({
    //   label: 'History',
    //   icon: <HistoryRoundedIcon />,
    //   url: insertIdToRoute(HISTORY, app.id),
    //   exactUrl: true,
    // });

    items.unshift({
      label: 'Security',
      icon: <SecurityOutlinedIcon />,
      url: createSecurityRoute(security.ROOT, app.id, 'latest'),
      exactUrl: false,
    });
  } else {
    items.push({
      label: 'Security',
      icon: <SecurityOutlinedIcon />,
      url: createSecurityRoute(security.ROOT, app.id, 'latest'),
      exactUrl: false,
    });
  }

  return items;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  item: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    backgroundColor: COLORS.GREY_6,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'all .2s linear',
    minWidth: theme.spacing(12.5),
    boxSizing: 'border-box',

    '&:not($active):hover': {
      backgroundColor: COLORS.GREY_4,
    },
  },
  active: {
    backgroundColor: COLORS.BLUE_1,
    color: COLORS.WHITE,
  },
  floatRight: {
    marginLeft: 'auto',
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  className?: string;
  agent: AgentDTO;
}

export const Menu = (props: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const links = generateMenuItems(props.application, props.agent).filter(({ hide }) => !hide);

  const isActive = ({ url, exactUrl }: MenuLink) => {
    if (exactUrl && location.pathname === url) {
      return true;
    } else if (location.pathname.startsWith(url)) {
      return true;
    } else if (location.pathname.includes(url)) {
      return true;
    }

    return false;
  };

  return (
    <div className={cx(classes.root, props.className)}>
      {links.map((link, idx) => (
        <Link
          replace
          to={link.url}
          className={cx(classes.item, isActive(link) && classes.active, link.floatRight && classes.floatRight)}
          key={idx}
        >
          {link.icon}
          {link.label}
        </Link>
      ))}
    </div>
  );
};
