import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
// import { LoadingContainer } from 'components/LoadingContainer';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO, SecurityScanDTO } from 'dtos/application';
import { DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
// import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
// import { useChartsEnabled } from 'hooks/useChartsEnabled';
import { uniq } from 'lodash';
import uniqBy from 'lodash/uniqBy';
// import { useCurrentAgent } from 'queries/useAgent';
// import { useApplicationSecurityScan } from 'queries/useApplications';
// import { useDeepScan, useDeepScanNetworkHosts, useDeepScanTargets } from 'queries/useDeepScan';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute, ROUTES } from 'telivy-constants';
import { isScanApplication } from 'telivy-selectors';

import { ApplicationViewContainer } from '../../ApplicationViewContainer';
import { InventoryDevices } from './Devices';
import { InventoryDomains } from './Domains';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  PARENT: string;
  ROOT: string;
  DOMAINS: string;
  DEVICES: string;
};

interface Props {
  application: ApplicationWithAgentDTO;
  securityScan: SecurityScanDTO | undefined;
  deepScanTargets: DeepScanTargetDTO[];
  deepScanNetworkHosts: DeepScanNetworkHostDTO[] | undefined;
  deepScanId: string;
  isAdmin: boolean;
  enableCharts: boolean;
  sourceRoute: SourceRoute;
}

export const NetworkView = ({
  application,
  deepScanTargets,
  deepScanNetworkHosts,
  securityScan,
  deepScanId,
  isAdmin,
  enableCharts,
  sourceRoute,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const externalScanDisabled = useExternalScanDisabled();

  const viewOptions: Option<ValueOf<SourceRoute>>[] = React.useMemo(() => {
    const options = [
      {
        label: `Devices (${deepScanNetworkHosts ? uniqBy(deepScanNetworkHosts, 'ipAddress').length : 0})`,
        value: createSecurityRoute(sourceRoute.DEVICES, application.id),
      },
    ];

    if (securityScan) {
      const allDomains = [securityScan.domain]
        .concat(securityScan.subDomains || [], securityScan.ipAddresses || [], securityScan.otherDomains || [])
        .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1);

      options.push({
        label: `Domains / IP Addresses (${uniq(allDomains).length})`,
        value: createSecurityRoute(sourceRoute.DOMAINS, application.id),
      });
    }

    return options;
  }, [sourceRoute, application, deepScanNetworkHosts, securityScan]);

  if (!isScanApplication(application)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  // if (isLoading) {
  //   return <LoadingContainer />;
  // }

  return (
    <div className={classes.root}>
      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.DOMAINS}
          element={
            securityScan && (
              <InventoryDomains
                securityScan={securityScan}
                securityScanId={securityScan.id}
                applicationId={application.id}
                application={application}
                sourceRoute={ROUTES.agent.application}
                externalScanDisabled={externalScanDisabled}
              />
            )
          }
        />

        <Route
          path={sourceRoute.DEVICES}
          element={<InventoryDevices deepScanTargets={deepScanTargets} deepScanNetworkHosts={deepScanNetworkHosts} />}
        />

        <Route index element={<Navigate replace to={sourceRoute.DEVICES} />} />
      </Routes>
    </div>
  );
};
